import { useEffect, useRef, useState } from "react"
import { LangfuseWeb } from "langfuse"
import { Badge } from "src/components/ui/Badge"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import { Input } from "src/components/ui/Input"
import { Label } from "src/components/ui/Label"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "src/components/ui/Table"

// Initialize Langfuse
const langfuseWeb = new LangfuseWeb({
	publicKey: process.env.NEXT_PUBLIC_LANGFUSE_PUBLIC_KEY,
	baseUrl: process.env.NEXT_PUBLIC_LANGFUSE_BASEURL,
})

// Reusable fetch function
const fetchFromLangfuse = async (
	endpoint: string,
	options: RequestInit = {}
) => {
	const response = await fetch(
		`https://us.cloud.langfuse.com/api/public/${endpoint}`,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization:
					"Basic cGstbGYtNjU1MmEyNDgtYTY2YS00MTk0LWIyMjItZDY5YmZkMzAxMjM4OnNrLWxmLTIyODczZmY3LTcxY2ItNDY2My04Y2JjLWQ2MmRjMjdiOTE1Nw==",
			},
			...options,
		}
	)
	if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
	return response.json()
}

interface Score {
	id: string
	timestamp: string
	projectId: string
	name: string
	source: string
	authorUserId: string | null
	comment: string | null
	traceId: string // Add this line
	observationId: string | null
	configId: string | null
	createdAt: string
	updatedAt: string
	value: number
	stringValue: string | null
	dataType: string
	trace: {
		userId: string | null
		id: string
	}
}

interface ScoresResponse {
	data: Score[]
	meta: {
		page: number
		limit: number
		totalItems: number
		totalPages: number
	}
}

interface TraceDetails {
	id: string
	name: string
	userId: string | null
	input: any
	output: any
	scores: Array<{
		name: string
		value: number
	}>
	observations: Array<{
		name: string
		type: string
		input: any
		output: any
		model?: string
		latency?: number
	}>
}

export default function AdminScreen() {
	const [scores, setScores] = useState<Score[]>([])
	const [activeIndex, setActiveIndex] = useState(0)
	const [feedback, setFeedback] = useState<number>(0)
	const [traceDetails, setTraceDetails] = useState<TraceDetails | null>(null)
	const tableRef = useRef<HTMLTableElement>(null)
	const [manualScore, setManualScore] = useState<number | "">("")

	useEffect(() => {
		fetchScores()
	}, [])

	const fetchScores = async () => {
		try {
			const data: ScoresResponse = await fetchFromLangfuse("scores")
			console.log("🎾 • ScoresResponse:", data)

			setScores(data.data)
			if (data.data.length > 0 && data.data[0].trace?.id) {
				await fetchTraceDetails(data.data[0].trace.id)
			}
		} catch (error) {
			console.error("Error fetching scores:", error)
		}
	}
	const fetchTraces = async () => {
		try {
			const data = await fetchFromLangfuse("traces")
			console.log("🔍 • Traces:", data)
			// Process or set traces data as needed
		} catch (error) {
			console.error("Error fetching traces:", error)
		}
	}

	useEffect(() => {
		fetchTraces()
	}, [])

	const fetchTraceDetails = async (traceId: string) => {
		try {
			const data = await fetchFromLangfuse(`traces/${traceId}`)
			console.log("Trace details:", data) // Add this line
			setTraceDetails({
				id: data.id,
				name: data.name,
				userId: data.userId,
				input: data.input,
				output: data.output,
				scores:
					data.scores?.map((score: any) => ({
						name: score.name,
						value: score.value,
					})) || [],
				observations:
					data.observations?.map((obs: any) => ({
						name: obs.name,
						type: obs.type,
						input: obs.input,
						output: obs.output,
						model: obs.model,
						latency: obs.latency,
					})) || [],
			})
		} catch (error) {
			console.error("Error fetching trace details:", error)
			setTraceDetails(null)
		}
	}

	const handleKeyDown = async (e: React.KeyboardEvent) => {
		if (e.key >= "0" && e.key <= "5") {
			const newFeedback = parseInt(e.key)
			setFeedback(newFeedback)
			await handleUserFeedback(newFeedback, scores[activeIndex].id)
		} else if (e.key === "Enter") {
			setActiveIndex(prev => (prev < scores.length - 1 ? prev + 1 : prev))
			setFeedback(0)
		} else if (e.key === "ArrowUp") {
			setActiveIndex(prev => (prev > 0 ? prev - 1 : prev))
		} else if (e.key === "ArrowDown") {
			setActiveIndex(prev => (prev < scores.length - 1 ? prev + 1 : prev))
		}
	}

	const handleUserFeedback = async (value: number, scoreId: string) => {
		try {
			const data = await fetchFromLangfuse(`scores/${scoreId}`, {
				method: "POST",
				body: JSON.stringify({
					name: "manual_score",
					value: value,
					comment: "User feedback from admin screen",
				}),
			})
			console.log(`Score updated for ${scoreId} with value ${value}`, data)
			await fetchScores() // Refresh the scores after updating
		} catch (error) {
			console.error("Error scoring feedback:", error)
		}
	}

	const handleRowClick = async (index: number) => {
		setActiveIndex(index)
		setFeedback(0)
		const traceId = scores[index]?.traceId
		if (traceId) {
			try {
				await fetchTraceDetails(traceId)
			} catch (error) {
				console.error("Error fetching trace details:", error)
				setTraceDetails(null)
			}
		} else {
			console.error("No valid trace ID found for the selected score")
			setTraceDetails(null)
		}
	}

	const handleRowKeyDown = (
		e: React.KeyboardEvent<HTMLTableRowElement>,
		index: number
	) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault()
			handleRowClick(index)
			// Added a call to fetchTraceDetails here
			fetchTraceDetails(scores[index]?.traceId)
		}
	}

	useEffect(() => {
		tableRef.current?.focus()
	}, [activeIndex])

	const handleScoreSubmit = async () => {
		if (manualScore !== "" && traceDetails) {
			await handleUserFeedback(Number(manualScore), traceDetails.id)
			setManualScore("")
		}
	}

	return (
		<div
			className="container flex flex-col gap-4 p-4 mx-auto md:flex-row"
			onKeyDown={handleKeyDown}
		>
			<Card className="w-full md:w-1/2">
				<CardHeader>
					<CardTitle>Admin Screen</CardTitle>
				</CardHeader>
				<CardContent>
					<Table ref={tableRef} tabIndex={0}>
						<TableHeader>
							<TableRow>
								<TableHead>Name</TableHead>
								<TableHead>Created At</TableHead>
								<TableHead>Value</TableHead>
								<TableHead>User ID</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{scores.map((score, index) => (
								<TableRow
									key={score.id}
									className={index === activeIndex ? "bg-muted" : ""}
									onClick={() => handleRowClick(index)}
									role="button"
									tabIndex={0}
									onKeyDown={e => handleRowKeyDown(e, index)}
								>
									<TableCell>{score.name}</TableCell>
									<TableCell>
										{new Date(score.createdAt).toLocaleString()}
									</TableCell>
									<TableCell>{score.value}</TableCell>
									<TableCell>{score.trace.userId || "N/A"}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
			<Card className="space-y-6 w-full md:w-1/2">
				<CardHeader>
					<CardTitle>Trace Details</CardTitle>
				</CardHeader>
				{traceDetails && (
					<CardContent>
						<div className="space-y-4">
							<div>
								<Label>Trace ID</Label>
								<Input value={traceDetails.id} readOnly />
							</div>
							<div>
								<Label>Name</Label>
								<Input value={traceDetails.name} readOnly />
							</div>
							<div>
								<Label>User ID</Label>
								<Input value={traceDetails.userId || "N/A"} readOnly />
							</div>
							<div>
								<Label>Input</Label>
								<pre className="overflow-auto p-2 max-h-40 rounded-md bg-muted">
									{JSON.stringify(traceDetails.input, null, 2)}
								</pre>
							</div>
							<div>
								<Label>Output</Label>
								<pre className="overflow-auto p-2 max-h-40 rounded-md bg-muted">
									{JSON.stringify(traceDetails.output, null, 2)}
								</pre>
							</div>
							<div>
								<Label>Scores</Label>
								<div className="flex flex-wrap gap-2">
									{traceDetails.scores.map((score, index) => (
										<Badge key={index} variant="secondary">
											{score.name}: {score.value}
										</Badge>
									))}
								</div>
							</div>
							<div>
								<Label>Observations</Label>
								<div className="space-y-2">
									{traceDetails.observations.map((obs, index) => (
										<div key={index} className="p-2 rounded-md bg-muted">
											<p>
												<strong>{obs.name}</strong> ({obs.type})
											</p>
											{obs.model && <p>Model: {obs.model}</p>}
											{obs.latency && <p>Latency: {obs.latency}ms</p>}
											<details>
												<summary>Input/Output</summary>
												<pre className="overflow-auto mt-2 max-h-40 text-xs">
													{JSON.stringify(
														{ input: obs.input, output: obs.output },
														null,
														2
													)}
												</pre>
											</details>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="mt-4 space-y-2">
							<Label htmlFor="manualScore">Manual Score (0-5)</Label>
							<div className="flex space-x-2">
								<Input
									id="manualScore"
									type="number"
									min="0"
									max="5"
									step="0.1"
									value={manualScore}
									onChange={e => setManualScore(Number(e.target.value))}
								/>
								<Button onClick={handleScoreSubmit}>Submit Score</Button>
							</div>
						</div>
					</CardContent>
				)}
			</Card>
		</div>
	)
}
