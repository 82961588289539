import { useEffect, useState } from "react"
import { PlusCircleIcon } from "lucide-react"
import { Card, CardHeader, CardTitle } from "src/components/ui/Card"
import CreatePersona from "src/components/ui/CreatePersona"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "src/components/ui/Dialog"
import trpc_client from "src/lib/trpc"

import { PublicSafePersona } from "../../../../../api/src/models/persona/persona.schema"
import { PersonaCard } from "../../../components/organisms/Persona/PersonaCard"

export const PersonasScreen: React.FC = () => {
	const [isDialogOpen, setIsDialogOpen] = useState(false)

	const [personas, setPersonas] = useState<PublicSafePersona[]>([])

	const getPersonas = async () => {
		const personas = await trpc_client.persona.listPersonas.query()
		setPersonas(personas)
	}

	useEffect(() => {
		getPersonas()
	}, [])

	return (
		<div className="flex flex-col w-full min-h-screen">
			<section className="flex flex-col flex-1 gap-4 p-4">
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
					<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
						<DialogTrigger className="flex flex-col flex-1">
							<Card className="flex flex-row flex-1 items-end w-full">
								<CardHeader>
									<CardTitle>
										<PlusCircleIcon className="mb-2 size-6" />
										Create New Persona
									</CardTitle>
								</CardHeader>
							</Card>
						</DialogTrigger>
						<DialogContent className="max-w-2xl">
							<DialogHeader>
								<DialogTitle>Create New Persona</DialogTitle>
								<DialogDescription>
									Add a new bot to your collection.
								</DialogDescription>
							</DialogHeader>
							<CreatePersona
								invite_content_creator={false}
								onSuccess={() => {
									setIsDialogOpen(false)
									getPersonas()
								}}
							/>
						</DialogContent>
					</Dialog>

					{personas.map(persona => (
						<PersonaCard key={persona.id} persona={persona} />
					))}
				</div>
			</section>
		</div>
	)
}
